import React from "react";
import styled from "styled-components";

const RankTable = styled.div`
  table {
    min-width: fit-content;
    width: 100%;
  }

  tr:nth-child(even) {
    background-color: #f1f1f1;
  }
  th {
    background-color: #eaeaea;
  }

  th,
  td {
    text-align: center;
    padding: 10px;
  }

  @media (min-width: 780px) {
    max-height: 70vh;
  }

  .tooltip {
    position: relative;
    cursor: pointer;
  }

  .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  .tooltip-text {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    transition: opacity 0.2s ease-in-out;
  }
`;

const Table = (props) => {
  const { data } = props;

  if (data) {
    try {
      if (data[0]["points"]) {
        for (let i = 0; i < data.length; i++) {
          delete data[i]["type"];
        }
      }

      if (data[0].name) {
        for (let i = 0; i < data.length; i++) {
          delete data[i].points;
          delete data[i].class;
          delete data[i].subClass;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }
  if (!Array.isArray(data) || !data[0]) return <></>;
  const nicknameCount = data.reduce((acc, curr) => {
    acc[curr.name] = (acc[curr.name] || 0) + 1;
    return acc;
  }, {});
  return (
    <RankTable>
      <table>
        <thead>
          <tr>
            <th>Position</th>
            {Object.keys(data[0]).map((key) => {
              if (
                key === "position" ||
                key === "guild" ||
                key === "isVIP" ||
                key === "class" ||
                key === "subClass"
              ) {
                return;
              }

              if (key === "Soma Level") {
                return <th>Level Total</th>;
              }
              return <th key={key}>{key[0].toUpperCase() + key.slice(1)}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}º</td>
                {Object.keys(row).map((value) => {
                  if (
                    value === "position" ||
                    value === "isVIP" ||
                    value === "class" ||
                    value === "subClass"
                  ) {
                    return;
                  }

                  if (value === "guildMark") {
                    let link =
                      row[value] === "w00000000"
                        ? `https://wydmisc.raidhut.com.br/guild/guild0.gif`
                        : `https://wydmisc.raidhut.com.br/guild/img_guilds/global/${row[value]}.bmp`;
                    return (
                      <td>
                        <img src={link} alt="" />
                      </td>
                    );
                  }

                  if (value === "kingdom") {
                    return (
                      <td>
                        <img
                          src={`https://wydmisc.raidhut.com.br/guild/${row[value]}.gif`}
                          alt=""
                        />
                      </td>
                    );
                  }

                  if (value === "guild") {
                    return;
                  }

                  if (value === "name") {
                    const isDuplicate = nicknameCount[row[value]] > 1;
                    return (
                      <td key={value}>
                        <div className="tooltip">
                          {row[value]}
                          {isDuplicate && (
                            <span className="tooltip-text">
                              Jogador possui mais de um personagem com esse nome
                            </span>
                          )}
                        </div>
                      </td>
                    );
                  }
                  return <td key={value}>{row[value]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </RankTable>
  );
};

export default Table;
